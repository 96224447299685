import { z } from 'zod';
import * as s from './shared';

const boatTypeSchema = s.wpOptionEntrySchema.extend({
  boatmodel: z.array(z.number()),
  boat_serie: z.array(z.number()),
  prices: s.pricesSchema.optional(),
  acf: z.object({
    tagline: z.string(),
    boatimage: s.wpImageSchema,
    standard_equipment: z.union([
      z.boolean(),
      z.array(
        z.object({
          title: z.string(),
        })
      ),
    ]),
    boat_class: z.string(),
    shapediver_ticket_id: z.string(),
    shapediver_model_view_url: z.string(),
    main: s.shapediverPerspectiveSchema,
    drivetrains: s.shapediverPerspectiveSchema,
    seats: s.shapediverPerspectiveSchema,
    tops: s.shapediverPerspectiveSchema,
    main_position_object: z.string(),
    drivetrains_position_object: z.string(),
    seats_position_object: z.string(),
    tops_position_object: z.string(),

    price: z.string(),
    price_eur: z.string(),
    price_sek: z.string(),
    price_usd: z.string(),
  }),
});

export type BoatOption = z.infer<typeof boatTypeSchema>;

export default z.array(boatTypeSchema);
