// Base
import React, { Component } from 'react'
import firebase from 'firebase/app'
import 'firebase/functions'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import { loadStripe } from '@stripe/stripe-js'
import { config } from '../../config'

// Components
import SimpleButton from '../../components/SimpleButton/SimpleButton'
import LogoResponsive from '../../components/LogoResponsive'
import Faq from '../../components/Faq'
import Section from '../../components/Section/Section'

// Store
import { getCommonOptions } from '../../store/Common/actions'
import { getOrder } from '../../store/Orders/selectors'
import { getOptions } from '../../store/Options/selectors'
import { fetchOptions } from '../../store/Options/actions'
import { loadOrder, syncOrder, updateOrder } from '../../store/Orders/actions'
import { prepareDepositCheckout } from '../../api/firebase'

const Navbar = styled.nav`
  width: 100%;
  height: 60px;
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 99999;
`

const Label = styled.label`
  input:focus + div {
    box-shadow: 0 0 0 3px gold;
    outline: 3px solid transparent;
  }
`

const Wrapper = styled.section`
  background: white;
`

const StyledP = styled.p`
  a {
    color: #fb5515;
  }
`

// Checkbox for consent
const Checkbox = ({ name, checked, handleClick }) => {
  return (
    <Label
      htmlFor={name}
      className={`pointer flex align-start pa2 bt b--light-gray ${checked && 'bg-lightest-blue'}`}
      style={{ background: '#eaf1f0', paddingTop: '1.5rem', paddingBottom: '1.5rem' }}
    >
      <input
        className="w3 h3 absolute top-0 left-0 o-0"
        type="checkbox"
        checked={checked}
        id={name}
        onClick={() => handleClick()}
      />
      <div
        className={`ba bw1 b--near-white bg-white br-100`}
        style={{ width: '20px', height: '20px', borderColor: '#e6e6e6' }}
      >
        <div
          className={`br-100 ${!checked && 'o-0'}`}
          style={{ width: '12px', height: '12px', margin: '2px', background: '#fb6e37' }}
        ></div>
      </div>
      <div className="ml3">
        <h4 className="f5 geo-semibold mb2 mt0">Accept terms</h4>
        <p className="f5 geo-regular mt0">
          By clicking "Accept and go to payment" you agree to the terms of the Goldfish Sales &
          Purchase Agreement.{' '}
          <a
            className="link geo-semibold hover-orange"
            target="_blank"
            style={{ color: '#fb6e37' }}
            href={config.warrantyTermsUrl}
            rel="noopener noreferrer"
          >
            Read the whole agreement here
          </a>
        </p>
      </div>
      {/* {touched && error && <span className="mt1 db red roboto-regular">{error}</span>} */}
    </Label>
  )
}

class OrderConfirmation extends Component {
  constructor(props) {
    super(props)
    this.state = { consent: false }
    this.handleConfirmation = this.handleConfirmation.bind(this)
    this.handleConsent = this.handleConsent.bind(this)
  }

  componentDidMount() {
    this.props.getCommonOptions()
    this.props.loadOrder(this.props.match.params.id)
  }

  handleConfirmation = async () => {
    const { id } = this.props.match.params

    const buildId = id

    const { email, boat, drivetrain } = this.props.order
    const { options } = this.props

    const currency = this.props.order.currency.toLowerCase()
    const boatTitle = options.boats.entities[boat].title.rendered
    const drivetrainTitle = options.drivetrains.entities[drivetrain].acf.display_name

    this.props.updateOrder({ status: 'Pending Payment' }, id)

    try {
      const response = await prepareDepositCheckout({
        email,
        title: `${boatTitle} - ${drivetrainTitle}`,
        currency,
        buildId,
      })
      const CHECKOUT_SESSION_ID = response.data.id
      const PAYMENT_INTENT = response.data.payment_intent
      await this.props.updateOrder(
        {
          stripeCheckoutSessionId: CHECKOUT_SESSION_ID,
          stripePaymentIntentId: PAYMENT_INTENT,
        },
        buildId
      )

      const stripe = await loadStripe(config.stripe.publishableKey)

      const { error } = await stripe.redirectToCheckout({
        // Make the id field from the Checkout Session creation API response
        // available to this file, so you can provide it as parameter here
        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        sessionId: CHECKOUT_SESSION_ID,
      })
      console.error(error)
    } catch (error) {
      console.error(error)
    }
  }

  handleConsent() {
    this.setState({ consent: this.state.consent === true ? false : true })
  }

  render() {
    const { consent } = this.state
    const { terms, isFetching, isOptionsFetching } = this.props

    if (isOptionsFetching || isFetching) {
      return (
        <>
          <Navbar className="ph3 ph4-ns flex items-center justify-between dn-print">
            <LogoResponsive></LogoResponsive>
            {/* <div className="mw5 ">
              <NextLink to="http://helpdesk.goldfishboat.com/" target="_blank">
                Help & Support
              </NextLink>
            </div> */}
          </Navbar>
        </>
      )
    }
    return (
      <>
        <Navbar className="ph3 ph4-ns flex items-center justify-between dn-print">
          <LogoResponsive></LogoResponsive>
          {/* <div className="mw5 ">
            <NextLink to="http://helpdesk.goldfishboat.com/" target="_blank">
              Help & Support
            </NextLink>
          </div> */}
        </Navbar>
        <Section>
          <Wrapper className="pb4 pt3 pt3-ns ml-auto mr-auto" style={{ maxWidth: '600px' }}>
            {/* <ScrollToTopOnMount /> */}
            <Helmet title="Confirm order" />
            <div className="mw7 center flex flex-wrap">
              <>
                <section className="mw8 ph3 center">
                  <h1 className="tc geo-regular f5 mt4 mb0">Checkout</h1>
                  <h2 className="tc geo-semibold f3 mt1">Terms and conditions</h2>
                  {terms &&
                    terms.map((term, index) => (
                      <div className="flex align-start mb3 " key={index}>
                        <div className="w2 mh2">
                          <img src={term.icon} alt=""></img>
                        </div>
                        <div className="w-100">
                          <h4 className="f5 geo-semibold mb2 mt0">{term.headline}</h4>
                          <StyledP className="f5 geo-regular mt0">
                            <div dangerouslySetInnerHTML={{ __html: term.text }} />
                          </StyledP>
                        </div>
                      </div>
                    ))}
                  <Checkbox
                    name={'termsAndConditions'}
                    checked={this.state.consent}
                    handleClick={this.handleConsent}
                  />
                  <div style={{ width: '100%' }}>
                    {consent && (
                      <div className="flex-auto flex-none-ns  center mt3 mb3">
                        <SimpleButton onClick={() => this.handleConfirmation()}>
                          Accept and go to payment
                        </SimpleButton>
                      </div>
                    )}
                    {!consent && <SimpleButton>Accept terms to proceed</SimpleButton>}
                  </div>
                </section>
              </>
            </div>
            <Faq></Faq>
          </Wrapper>
        </Section>
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  requestBuildFaqs: state.common.requestBuildFaqs,
  terms: state.common.options && state.common.options.terms,
  order: getOrder(state, ownProps.match.params.id),
  options: getOptions(state),
  isFetching: state.orders._isFetching,
  isOptionsFetching: state.options._isFetching,
})

// Then connect the whole with the redux store
export default connect(mapStateToProps, {
  getCommonOptions,
  updateOrder,
  loadOrder,
  fetchOptions,
  syncOrder,
})(OrderConfirmation)

// export default OrderConfirmation
