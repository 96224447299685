import { config } from '../config';

export interface BoatDefinition {
  id: number;
  title?: string | null;
  slug?: string | null;
  shapediver_ticket_id?: string | null;
  shapediver_model_view_url?: string | null;
  image?: string | null;
}

export interface BoatDefinitions {
  boats: Array<BoatDefinition>;
}

/**
 * Get a list of boat definitions and a tiny subset of details about each boat.
 *
 * This (as of 2023-08-03) is only used in the admin section, the orders list to
 * display the correct boat name for each order.
 *
 * @returns A list of boat definitions, with a subset of details.
 */
export async function getBoatDefinitions(): Promise<Array<BoatDefinition>> {
  const url = `${config.wordpressRootUrl}/wp-json/builder/v1/boats`;

  const res = await fetch(url, {
    credentials: 'include',
  });

  const data = (await res.json()) as BoatDefinitions;

  return data.boats;
}
