import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Viewer } from './Viewer';
import camelCase from 'camel-case';
import paramCase from 'param-case';
import { useGlobalDevelopmentSettings } from '../../development/useGlobalDevelopmentSettings';

const objectStringsToFLoats = (obj: any) => {
  return Object.keys(obj).map((k) => parseFloat(obj[k]));
};

export function BoatImagePoc(props: any) {
  const [devSettings, _setDevSettings] = useGlobalDevelopmentSettings();

  const {
    build: { drivetrain, seat, top, colors = {}, boatSlug, equipmentLineItems, addonLineItems },
    options: { drivetrains, seats, tops, colorareas, equipments, addons },
    boat: {
      acf: { shapediver_ticket_id, shapediver_model_view_url },
    },
    visibleScroller,
    lastChosenEquipmentId,
    lastChosenAddonId,
  } = props;
  const { boat } = props;

  if (!boat) {
    return <p>Loading</p>;
  }
  //
  // Create parameter map for shapediver
  const options = props.options;
  let parameterMap = {} as any;
  // console.log(boat?.acf?.tops_position_object ? 'true' : 'false')
  const cameraPositions = {
    // main: JSON.parse(boat?.acf?.camera_position_object),
    main: {
      position: boat?.acf?.main_position_object
        ? JSON.parse(boat?.acf?.main_position_object)['position']
        : objectStringsToFLoats(boat.acf.main.position),
      target: boat?.acf?.main_position_object
        ? JSON.parse(boat?.acf?.main_position_object)['target']
        : objectStringsToFLoats(boat.acf.main.target),
    },
    seats: {
      position: boat?.acf?.seats_position_object
        ? JSON.parse(boat?.acf?.seats_position_object)['position']
        : objectStringsToFLoats(boat.acf.seats.position),
      target: boat?.acf?.seats_position_object
        ? JSON.parse(boat?.acf?.seats_position_object)['target']
        : objectStringsToFLoats(boat.acf.seats.target),
    },
    tops: {
      position: boat?.acf?.tops_position_object
        ? JSON.parse(boat?.acf?.tops_position_object)['position']
        : objectStringsToFLoats(boat.acf.tops.position),
      target: boat?.acf?.tops_position_object
        ? JSON.parse(boat?.acf?.tops_position_object)['target']
        : objectStringsToFLoats(boat.acf.tops.target),
    },
    drivetrains: {
      position: boat?.acf?.drivetrains_position_object
        ? JSON.parse(boat?.acf?.drivetrains_position_object)['position']
        : objectStringsToFLoats(boat.acf.drivetrains.position),
      target: boat?.acf?.drivetrains_position_object
        ? JSON.parse(boat?.acf?.drivetrains_position_object)['target']
        : objectStringsToFLoats(boat.acf.drivetrains.target),
    },
  } as any;

  let sdEquipments = {} as any;

  if (equipmentLineItems) {
    equipmentLineItems?.forEach((o: any) => {
      const item = equipments.entities[o.equipment];
      //
      // console.log(item)
      if (item?.acf?.shapediver_option_id && item?.acf?.shapediver_property_id) {
        sdEquipments[item?.acf?.shapediver_property_id] = item?.acf?.shapediver_option_id;
      }
    });
  }

  let sdAddons = {} as any;
  if (addonLineItems) {
    // console.log(addons)
    addonLineItems?.forEach((o: any) => {
      const item = addons.entities[o.addon];
      //
      // console.log(item)
      if (item?.acf?.shapediver_option_id && item?.acf?.shapediver_property_id) {
        sdAddons[item?.acf?.shapediver_property_id] = item?.acf?.shapediver_option_id;
      }
    });
  }

  Object.keys({ drivetrains, seats, tops }).forEach((key) => {
    const it = options[key];
    //
    let temp = {} as any;
    temp.parameterId = it.entities[Object.keys(it.entities)[0]].acf.shapediver_property_id;
    Object.keys(it.entities).forEach((key) => {
      const item = it.entities[key];
      //
      const name = paramCase(item.acf.display_name);
      //
      temp[name] = item.acf.shapediver_option_id;
    });
    let k = key;

    parameterMap[k] = temp;
  });

  Object.keys(colorareas.entities).forEach((key) => {
    const it = colorareas.entities[key];

    let temp = {} as any;
    temp.parameterId = it.acf.shapediver_property_id;
    const name = camelCase(it.acf.display_name);
    it.acf.colors.forEach((color: any) => {
      temp[color.color.slug] = color.shapediver_property_id;
    });
    parameterMap[name] = temp;

    if ((it.acf.position && it.acf.target) || it.acf.camera_position_object) {
      const position = it.acf.camera_position_object
        ? JSON.parse(it?.acf?.camera_position_object)['position']
        : Object.keys(it.acf.position).map((k) => parseFloat(it.acf.position[k]));
      const target = it.acf.camera_position_object
        ? JSON.parse(it?.acf?.camera_position_object)['target']
        : Object.keys(it.acf.target).map((k) => parseFloat(it.acf.target[k]));
      cameraPositions[key] = {
        position,
        target,
      };
    }
  });
  // Parameter mapping done
  //

  //

  const drivetrainSlug =
    paramCase(drivetrains.entities[drivetrain]?.acf?.display_name) || undefined;
  const motorType = drivetrains.entities[drivetrain]?.acf?.motor_type_new.toLowerCase();
  const seatSlug = paramCase(
    seats.entities[seat] ? seats.entities[seat]?.acf?.display_name.toLowerCase() : 'patrol'
  );
  const topSlug = paramCase(tops.entities[top]?.acf?.display_name) || 'no-top';

  let colorMappings = {
    flooring: boatSlug === '28-bullet' ? 'grip-foam-black' : 'gelcoat-non-skid',
    // gelcoatHullDeck: 'pure-white', // TODO: Set this if not 29 sport
    upholstery: 'carbon-grey',
    plexiglas: 'dark-smoked',
  } as any;

  // eslint-disable-next-line
  Object.keys(colors).forEach((key) => {
    if (!colorareas.entities[key]) {
      console.log('No color area entity for this key', { key });
      console.log('Entities', colorareas.entities);
      return;
    }
    if (!colorareas.entities[key].acf) {
      console.log('No ACF for this color?', { key });
      console.log('Entities', colorareas.entities);
      return;
    }
    const area = camelCase(colorareas.entities[key].acf.display_name);
    const color = colorareas.entities[key]?.acf?.colors.find(
      (item: any) => item.color.term_id === Number(colors[key])
    );

    colorMappings[area] = color?.color?.slug;
  });

  let customCameraPosition = false as any;
  if (lastChosenEquipmentId) {
    customCameraPosition = {
      position: equipments.entities[lastChosenEquipmentId].acf.camera_position_object
        ? JSON.parse(equipments.entities[lastChosenEquipmentId].acf.camera_position_object)[
            'position'
          ]
        : objectStringsToFLoats(equipments.entities[lastChosenEquipmentId].acf.position),
      target: equipments.entities[lastChosenEquipmentId].acf.camera_position_object
        ? JSON.parse(equipments.entities[lastChosenEquipmentId].acf.camera_position_object)[
            'target'
          ]
        : objectStringsToFLoats(equipments.entities[lastChosenEquipmentId].acf.target),
    };
  }

  if (lastChosenAddonId) {
    customCameraPosition = {
      position: equipments.entities[lastChosenAddonId].acf.camera_position_object
        ? JSON.parse(equipments.entities[lastChosenAddonId].acf.camera_position_object)['position']
        : objectStringsToFLoats(equipments.entities[lastChosenAddonId].acf.position),
      target: equipments.entities[lastChosenAddonId].acf.camera_position_object
        ? JSON.parse(equipments.entities[lastChosenAddonId].acf.camera_position_object)['target']
        : objectStringsToFLoats(equipments.entities[lastChosenAddonId].acf.target),
    };
  }

  return (
    <Viewer
      boatSlug={boatSlug}
      cameraPositions={cameraPositions}
      parameterMap={parameterMap}
      visibleScroller={visibleScroller}
      customCameraPosition={customCameraPosition}
      {...colorMappings}
      motor={motorType}
      seats={seatSlug}
      drivetrains={drivetrainSlug}
      tops={topSlug}
      ticket={shapediver_ticket_id}
      modelViewUrl={shapediver_model_view_url}
      equipments={sdEquipments}
      addons={sdAddons}
    ></Viewer>
  );
}

// BoatImagePoc.propTypes = {
//   visibleScroller: PropTypes.string,
//   build: PropTypes.shape({
//     drivetrain: PropTypes.string.isRequired,
//     id: PropTypes.string,
//     seat: PropTypes.string.isRequired,
//     top: PropTypes.string.isRequired,
//     colors: PropTypes.object,
//     boatSlug: PropTypes.string,
//   }),
//   options: PropTypes.shape({
//     boats: PropTypes.object,
//     drivetrains: PropTypes.object.isRequired,
//     seats: PropTypes.object,
//     tops: PropTypes.object,
//     colorareas: PropTypes.object,
//   }),
// };

export default BoatImagePoc;
